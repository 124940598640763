var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v(_vm._s(_vm.formTitle)),
      ]),
      _c("vx-card", [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center mb-2" },
          [
            _c("vs-avatar", {
              staticClass: "mr-4 mb-4 company-logo-avatar",
              attrs: {
                src: _vm.form.groupImageUrl,
                icon: "cloud_upload",
                size: "70px",
              },
              on: {
                click: function ($event) {
                  return _vm.$refs.groupImageInput.click()
                },
              },
            }),
            _c(
              "div",
              [
                _c("input", {
                  ref: "groupImageInput",
                  attrs: { type: "file", hidden: "", id: "groupImageInput" },
                  on: { change: _vm.handleFileUpload },
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.groupImageInput.click()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.uploadImageButtonText))]
                ),
                _c("p", { staticClass: "text-sm mt-2" }, [
                  _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Group Name*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:100",
                    expression: "'required|max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Group Name" },
                model: {
                  value: _vm.form.groupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupName", $$v)
                  },
                  expression: "form.groupName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Group Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Event Name")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "max:100",
                    expression: "'max:100'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Event Name" },
                model: {
                  value: _vm.form.eventName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "eventName", $$v)
                  },
                  expression: "form.eventName",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Event Name"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Description*")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:5000",
                    expression: "'required|max:5000'",
                  },
                ],
                staticClass: "w-full mb-0",
                attrs: { name: "Description", rows: "8" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Description"))),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Event Location")]),
          ]),
          _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-start" },
              [
                !_vm.locationStateForEditor.name
                  ? _c(
                      "vs-button",
                      {
                        attrs: {
                          color: "primary",
                          size: "small",
                          type: "filled",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showLocationAutoComplete = true
                          },
                        },
                      },
                      [_vm._v("Set Location")]
                    )
                  : _vm._e(),
                _c("div", { attrs: { title: "Click to edit location" } }, [
                  _vm.locationStateForEditor
                    ? _c(
                        "a",
                        {
                          staticClass: "hover:underline",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              _vm.showLocationAutoComplete = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.locationStateForEditor.name))]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "vx-row mb-4" }, [
          _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
            _c("span", [_vm._v("Active")]),
          ]),
          _c(
            "div",
            { staticClass: "vx-col sm:w-4/5 w-full" },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.form.isActive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isActive", $$v)
                  },
                  expression: "form.isActive",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "danger", type: "filled" },
                on: { click: _vm.onCancel },
              },
              [_vm._v("Cancel")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-2",
                attrs: { color: "primary", type: "filled" },
                on: { click: _vm.onSave },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
      _vm.showLocationAutoComplete
        ? _c("location-editor-auto-complete", {
            attrs: {
              applicationId: _vm.applicationId,
              location: _vm.locationStateForAutoComplete,
              noSave: true,
            },
            on: {
              onEditSuccess: _vm.onEditSuccessAutoComplete,
              onCancel: function ($event) {
                _vm.showLocationAutoComplete = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }